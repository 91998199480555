<template>
  <v-container class="container--fluid grid-list-md">
    <h2 class="mb-2">{{$t('route.xmls.export')}}</h2>
    <a href="https://pricecreator-rozetka.tatet.net/seller/itemList">Ссылка</a><br>
  </v-container>
</template>

<script>
export default {
name: "exportXML"
}
</script>

<style scoped>

</style>
